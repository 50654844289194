import React from 'react';
import CocktailContainer from '../../containers/cocktail.container';
import { ICocktail } from '../../models/cocktail.model';
import './List.scss';
import SearchBar from './SearchBar';
import { NavLink, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { CocktailUtil } from '../../utils/cocktail.util';
import withContainers from '../../utils/with-containers';

interface IListProps {
  location: Location;
  cocktailContainer: CocktailContainer;
}

const List = ({ location, cocktailContainer }: IListProps) => {
  if (location && location.hash) {
    const cocktailId = location.hash.slice(1);
    setTimeout(() => {
      const listItem = document.getElementById(cocktailId);
      if (listItem) {
        listItem.scrollIntoView();
      }
    }, 10);
  }

  const [cocktails, setCocktails] = React.useState(cocktailContainer.state.cocktails);

  const filterCocktails = (query: string) => {
    const filteredCocktails = CocktailUtil.filterCocktailsByQuery(cocktailContainer.state.cocktails, query);
    setCocktails(filteredCocktails);
  };

  return (
    <div className="list-component">
      <SearchBar onSearchInputChange={filterCocktails} />
      <NewButton />
      {cocktails.map(cocktail => (
        <ListItem key={cocktail.name} cocktail={cocktail} />
      ))}
      <NewButton />
    </div>
  );
};

const NewButton = () => (
  <NavLink to="/new" className="new-button">Toevoegen</NavLink>
);

const ListItem = ({ cocktail }: { cocktail: ICocktail }) => {
  return (
    <NavLink id={`cocktail-${cocktail.id}`} className="list-item" to={`/${cocktail.id}`}>
      <h2>{cocktail.name}</h2>
      <p>{cocktail.ingredients.map(a => a.name).join(', ')}</p>
    </NavLink>
  );
};

export default compose<IListProps, {}>(
  withContainers({
    cocktailContainer: CocktailContainer,
  }),
  withRouter
)(List);
