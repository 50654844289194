import { Container } from 'unstated';
import { ICocktail } from '../models/cocktail.model';

const KEY_COCKTAIL = 'cocktails-21042019';

interface ICocktailContainerState {
  cocktails: ICocktail[];
  isLoading: boolean;
}

class CocktailContainer extends Container<ICocktailContainerState> {
  state = {
    isLoading: true,
    cocktails: [] as ICocktail[],
  };

  constructor() {
    super();
    this.init();
  }

  init = async () => {
    let cocktails = this.getCocktailsFromLocalStorage();
    if (!cocktails) {
      cocktails = await this.getCocktailsFromJson();
      this.saveCocktailsInLocalStorage(cocktails);
    }
    cocktails = this.sortCocktails(cocktails);
    this.setState({
      cocktails,
      isLoading: false,
    });
  };

  sortCocktails = (cocktails: ICocktail[]) => {
    return cocktails.sort((a, b) => (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1));
  };

  getCocktailsFromLocalStorage = () => {
    const cocktails = localStorage.getItem(KEY_COCKTAIL);
    return cocktails ? JSON.parse(cocktails) : null;
  };

  getCocktailsFromJson = async () => {
    const response = await fetch(`${process.env.PUBLIC_URL}/assets/cocktail-recipes.json`);
    return await response.json();
  };

  saveCocktailsInLocalStorage = (cocktails: ICocktail[]) => {
    localStorage.setItem(KEY_COCKTAIL, JSON.stringify(cocktails));
  };

  addCocktail = async (cocktail: ICocktail) => {
    const newId = this.state.cocktails.reduce((curr, acc) => curr > +acc.id ? curr : +acc.id, 0) + 1;
    cocktail.id = `${newId}`;
    await this.setState(
      state => ({ cocktails: [...state.cocktails, cocktail] }),
      () => this.saveCocktailsInLocalStorage(this.state.cocktails)
    );
    await this.init();
    return newId;
  };

  updateCocktail = async (cocktail: ICocktail) => {
    const cocktails = this.state.cocktails.map(c => (c.id === cocktail.id ? cocktail : c));
    await this.setState({ cocktails }, () => this.saveCocktailsInLocalStorage(cocktails));
    await this.init();
  };

  removeCocktail = async (cocktailId: string) => {
    return await this.setState(
      state => ({
        cocktails: state.cocktails.filter(a => {
          return a.id !== cocktailId;
        }),
      }),
      async () => {
        this.saveCocktailsInLocalStorage(this.state.cocktails);
        await this.init();
      }
    );
  };
}

export default CocktailContainer;
