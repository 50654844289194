import React from 'react';

const BaseLayout = ({children}: any) => {
  return (
    <div>
      {children}
    </div>
  );
};

export default BaseLayout;
