import React from 'react';

const Edit = () => (
  <svg width="70" height="72" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70">
    <g transform="translate(0 1)" fillRule="nonzero" fill="none">
      <circle fill="#D8D8D8" transform="rotate(90 35 35)" cx="35" cy="35" r="35" />
      <path
        d="M38.893 24.076l6.835 6.869L28.427 48.33l-6.831-6.869 17.297-17.386zm12.022-1.656l-3.048-3.063a3.014 3.014 0 0 0-4.273 0l-2.92 2.934 6.835 6.868 3.406-3.422a2.35 2.35 0 0 0 0-3.317zM18.019 51.116a.78.78 0 0 0 .94.93l7.617-1.856-6.83-6.868-1.727 7.794z"
        fill="#000"
      />
    </g>
  </svg>
);

export default Edit;
