import React from 'react';
import './Details.scss';
import { compose } from 'recompose';
import { withRouter, match } from 'react-router';
import CocktailContainer from '../../containers/cocktail.container';
import { NavLink } from 'react-router-dom';
import { Chevron } from '../../icons/Chevron';
import { Cross } from '../../icons/Cross';
import { history } from '../../utils/history.util';
import Edit from '../../icons/Edit';
import Remove from '../../icons/Remove';
import EditCocktail from '../Edit/Edit';
import withContainers from '../../utils/with-containers';

interface IDetailsProps {
  match: match<{ cocktail: string }>;
  cocktailContainer: CocktailContainer;
}

const Details = ({ match, cocktailContainer }: IDetailsProps) => {
  const [removeState, setRemoveState] = React.useState<'inactive' | 'active'>('inactive');
  const [edit, setEdit] = React.useState(false);

  const id = match.params.cocktail;
  const cocktail = cocktailContainer.state.cocktails.find(a => a.id === id);
  if (!cocktail) return null;

  window.scrollTo(0, 0);

  const onRemoveClick = async () => {
    if (removeState === 'inactive') {
      setRemoveState('active');
    } else if (removeState === 'active') {
      await cocktailContainer.removeCocktail(cocktail.id);
      history.push('/');
    }
  };

  if (edit) {
    return <EditCocktail cocktail={cocktail} onCancel={() => setEdit(false)} afterSubmit={() => setEdit(false)} />;
  }

  return (
    <div className="details-page">
      <NavLink className="back-button" to={`/#cocktail-${cocktail.id}`}>
        <Chevron />
      </NavLink>
      <button onClick={() => setEdit(true)} className={`edit-button ${removeState === 'active' && 'active'}`}>
        <Edit />
      </button>
      <button onClick={onRemoveClick} className={`remove-button ${removeState === 'active' && 'active'}`}>
        <Remove />
      </button>
      <h2>{cocktail.name}</h2>
      <hr />
      <p>
        {cocktail.ingredients.map((ingredient, index) => (
          <React.Fragment key={index}>
            {ingredient.quantity} {ingredient.name}
            <br />
          </React.Fragment>
        ))}
      </p>
      <hr />
      <p>{cocktail.instructions}</p>
    </div>
  );
};

export default compose<IDetailsProps, {}>(
  withContainers({
    cocktailContainer: CocktailContainer,
  }),
  withRouter
)(Details);
