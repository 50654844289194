import BaseLayout from './components/BaseLayout';
import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import List from './pages/List';
import Details from './pages/Details';
import Edit from './pages/Edit';
import { history } from './utils/history.util';

const Routes = () => {
  return (
    <Router history={history}>
      <BaseLayout>
        <Switch>
          <Route exact path="/" component={List} />
          <Route exact path="/new" component={Edit} />
          <Route path="/:cocktail" component={Details} />
        </Switch>
      </BaseLayout>
    </Router>
  );
};

export default Routes;
