import React from 'react';
import { Subscribe, ContainerType } from 'unstated';

type IContainers = { [key: string]: ContainerType<{}> };

/**
 * Receives a key value pair of containers subscribed to.
 * The key is used as the name of the prop which will be given
 * to the wrapped component.
 *
 * eg: { auth: AuthContainer }
 */
export default (containers: IContainers) => (WrappedComponent: React.ComponentClass | React.FunctionComponent) => (
  props: any
) => {
  // Object is turned into an array so we can spread it on the Subscribe
  // component, and later on get the containers back in the same order.
  const containerArray = Object.keys(containers).map(containerName => ({
    name: containerName,
    container: containers[containerName],
  }));

  return (
    <Subscribe to={[...containerArray.map(container => container.container)]}>
      {(...containerInstances) => {
        // Reduce over all containerInstances and create an object with key value
        // where key is the prop name and the value is the containerInstance that is
        // passed to the component.
        const containerProps = containerInstances.reduce((props: any, container, index) => {
          props[containerArray[index].name] = container;
          return props;
        }, {});

        return <WrappedComponent {...containerProps} {...props} />;
      }}
    </Subscribe>
  );
};
