import React from 'react';
import ReactDOM from 'react-dom';
import './styles/fonts.scss';
import './styles/normalize.scss';
import './styles/index.scss';
import Routes from './Routes';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'unstated';
import CocktailProvider from './components/CocktailProvider';

ReactDOM.render(
  <Provider>
    <CocktailProvider>
      <Routes />
    </CocktailProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
