import React from 'react';

const Remove = () => (
  <svg width="70" height="70" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70">
    <g fillRule="nonzero" fill="none">
      <circle transform="rotate(90 35 35)" cx="35" cy="35" r="35" fill="#D8D8D8" />
      <g fill="#000">
        <path d="M47.326 20.38H42.09v-3.384c0-.681-.553-1.209-1.238-1.209-.066 0-.11.022-.132.044a.169.169 0 0 0-.089-.044H28.147c-.684 0-1.215.528-1.215 1.209v3.383h-5.258C20.17 20.38 19 21.544 19 23.04v4.35h2.32V52.15c0 1.494 1.149 2.636 2.652 2.636h21.056c1.503 0 2.674-1.142 2.674-2.636V27.389H50v-4.351c0-1.494-1.171-2.659-2.674-2.659zm-17.963-2.176h10.274v2.175H29.363v-2.175zM45.25 52.151c0 .154-.067.22-.222.22H23.972c-.155 0-.221-.066-.221-.22V27.389H45.25V52.15zm2.297-27.18H21.453v-1.933c0-.154.066-.242.22-.242h25.653c.155 0 .221.088.221.242v1.934z" />
        <path d="M39 30.787h3v20h-3zM33 30.787h3v20h-3zM27 30.787h3v20h-3z" />
      </g>
    </g>
  </svg>
);

export default Remove;
