import React from 'react';
import './SearchBar.scss';

interface ISearchBarProps {
  onSearchInputChange: Function;
}

const SearchBar = (props: ISearchBarProps) => (
  <div className="search-bar-component">
    <input onChange={e => props.onSearchInputChange(e.target.value)} placeholder="zoek je iets?" />
  </div>
);

export default SearchBar;
