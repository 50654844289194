import React from "react";
import CocktailContainer from "../../containers/cocktail.container";
import { Subscribe } from "unstated";

interface ICocktailProviderProps {
  children: React.ReactNode;
}

const CocktailProvider = ({ children }: ICocktailProviderProps) => (
  <Subscribe to={[CocktailContainer]}>
    {cocktails => (
      <>{cocktails.state.isLoading ? <div>Loading...</div> : <>{children}</>}</>
    )}
  </Subscribe>
);

export default CocktailProvider;
