import React from 'react';

interface IChevronProps {
  className?: string;
  colorCircle?: string;
  colorChevron?: string;
}

export const Chevron = ({ className, colorCircle, colorChevron }: IChevronProps) => (
  <svg width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...className}>
    <g fill="none" fillRule="evenodd">
      <circle fill={colorCircle || '#D8D8D8'} transform="rotate(90 12.5 12.5)" cx="12.5" cy="12.5" r="12.5" />
      <path
        d="M15 17.532a1.473 1.473 0 0 1-2.154 0L8 12.5l4.846-5.032a1.473 1.473 0 0 1 2.154 0L10.154 12.5 15 17.532z"
        fillRule="nonzero"
        fill={colorChevron || '#000'}
      />
    </g>
  </svg>
);
