import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { ICocktail } from '../../models/cocktail.model';
import { Form, Field } from 'react-final-form';
import CocktailContainer from '../../containers/cocktail.container';
import withContainers from '../../utils/with-containers';
import { Cross } from '../../icons/Cross';
import './Edit.scss';
import { history } from '../../utils/history.util';

interface IEditPropsOuter {
  cocktail: ICocktail;
  afterSubmit: Function;
  onCancel: Function;
}

interface IEditPropsInner extends IEditPropsOuter {
  cocktailContainer: CocktailContainer;
}

const Edit = ({ cocktail, afterSubmit, cocktailContainer, onCancel }: IEditPropsInner) => {
  const [numberOfIngredients, setNumberOfIngredients] = React.useState(cocktail ? cocktail.ingredients.length : 3);
  const [ingredientInputs, setIngredientInputs] = React.useState<any[]>([]);
  const [initialValues, setInitialValues] = React.useState<any>(null);
  let initialIngredients: any = {};

  React.useEffect(() => {
    if (cocktail) {
      cocktail.ingredients.forEach((ingr, index) => {
        initialIngredients[`ingredient-${index}`] = {
          quantity: ingr.quantity,
          name: ingr.name,
        };
      });
      setInitialValues({ ...cocktail, ...initialIngredients });
    }
  }, []);

  React.useEffect(() => {
    const ingrInputs = [];
    for (let i = 0; i < numberOfIngredients; i++) {
      ingrInputs.push(
        <div className="input-group-ingredient" key={i}>
          <Field className="input-ingredient-quantity" name={`ingredient-${i}.quantity`} component="input" />
          <Field className="input-ingredient-name" name={`ingredient-${i}.name`} component="input" />
        </div>
      );
      setIngredientInputs(ingrInputs);
    }
  }, [numberOfIngredients]);

  const onSubmit = async (formData: any) => {
    const ingr = Object.keys(formData)
      .filter(key => key.startsWith('ingredient-'))
      .map(key => ({ quantity: formData[key].quantity, name: formData[key].name }));

    const newCocktail = {
      id: cocktail ? cocktail.id : '',
      name: formData.name,
      ingredients: ingr,
      instructions: formData.instructions,
    };
    let newId;
    if (cocktail) {
      await cocktailContainer.updateCocktail(newCocktail);
    } else {
      newId = await cocktailContainer.addCocktail(newCocktail);
    }
    afterSubmit ? afterSubmit() : history.push(`/#cocktail-${newId}`);
  };

  return (
    <div className="edit-page">
      <button onClick={() => (onCancel ? onCancel() : history.push('/'))} className={`cancel-button`}>
        <Cross />
      </button>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="form">
            <Field className="input-name" name="name" component="input" />
            <hr />
            {ingredientInputs}
            <button
              onClick={e => {
                e.preventDefault();
                setNumberOfIngredients(numberOfIngredients + 1);
              }}>
              Ingredient toevoegen
            </button>
            <hr />
            <Field className="input-instructions" name="instructions" component="textarea" />
            <button type="submit">Opslaan</button>
          </form>
        )}
      />
    </div>
  );
};

export default compose<IEditPropsInner, IEditPropsOuter>(
  withContainers({
    cocktailContainer: CocktailContainer,
  }),
  withRouter
)(Edit);
