import { IIngredient } from '../models/ingredient.model';
import { ICocktail } from '../models/cocktail.model';

export class CocktailUtil {
  static clean = (str: string) => str.toLowerCase().trim();

  static cocktailNameContainsFragment = (name: string, fragments: string[]) =>
    !!fragments.find(f => CocktailUtil.clean(name).includes(CocktailUtil.clean(f)));

  static cocktailIngredientsContainFragment = (ingredients: IIngredient[], fragments: string[]) => {
    let meetsFragments = false;
    fragments.forEach(f => {
      let fragmentMeetsIngredient = false;
      ingredients.forEach(i => {
        if (CocktailUtil.clean(i.name).includes(CocktailUtil.clean(f))) {
          fragmentMeetsIngredient = true;
        }
      });
      meetsFragments = fragmentMeetsIngredient;
    });
    return meetsFragments;
  };

  static filterCocktailsByQuery = (cocktails: ICocktail[], query: string) => {
    if (query.length <= 2) {
      return cocktails;
    }

    const fragments = query.split(',').filter(a => a.length > 2);
    const filteredCocktails = cocktails.filter(
      cocktail =>
        CocktailUtil.cocktailNameContainsFragment(cocktail.name, fragments) ||
        CocktailUtil.cocktailIngredientsContainFragment(cocktail.ingredients, fragments)
    );
    return filteredCocktails;
  };
}
