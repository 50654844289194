import React from 'react';

interface IChevronProps {
  className?: string;
  colorCircle?: string;
  colorCross?: string;
}

export const Cross = ({ className, colorCircle, colorCross }: IChevronProps) => (
  <svg width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...className}>
    <g fill="none" fillRule="evenodd">
      <circle fill={colorCircle || '#D8D8D8'} transform="rotate(90 12.5 12.5)" cx="12.5" cy="12.5" r="12.5" />
      <g fillRule="nonzero" fill={colorCross || '#000'}>
        <path d="M18.5 17.532a1.473 1.473 0 0 1-2.154 0L11.5 12.5l4.846-5.032a1.473 1.473 0 0 1 2.154 0L13.654 12.5l4.846 5.032z" />
        <path d="M6.5 17.532a1.473 1.473 0 0 0 2.154 0L13.5 12.5 8.654 7.468a1.473 1.473 0 0 0-2.154 0l4.846 5.032L6.5 17.532z" />
      </g>
    </g>
  </svg>
);
